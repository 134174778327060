<div *ngIf="!end" class="components-container">
    <ng-container *ngIf="isCurrentStep">
        <div class="container is-fluid" data-cy="fai-eligibility">
            <h2 class="suptitle has-text-centered is-size-6-tablet">Test d'éligibilité Fibre, ADSL ou 4G Box</h2>
            <p class="title is-size-4 is-size-1-tablet has-text-centered"><span class="is-block">Découvrez les offres
                    internet<br>disponibles chez vous</span></p>
        </div>
    </ng-container>

    <div class="section has-background-transparent">

        <div class="columns is-centered is-marginless">
            <div class="column is-10">
                <div class="box">
                    <div class="box-content">
                        <div>
                            <div class="has-text-centered"
                                *ngIf="isCurrentStep && !isLoading">
                                <div class="columns is-centered">
                                    <div class="column">
                                        <div class="tabs is-centered is-fullwidth" role="tablist">
                                            <ul>
                                                <li role="presentation">
                                                    <a data-cy="by-address" role="tab" routerLink="/fai/address" (click)="onTabClick(TYPES.ADDRESS)"
                                                        [class.is-active]="currentType === TYPES.ADDRESS"
                                                        [attr.aria-selected]="currentType === TYPES.ADDRESS">Testez avec une
                                                        adresse</a>
                                                </li>
                                                <!-- TO DEVELOP  (ELIG WIDGET PTO) -->
                                                <li role="presentation">
                                                    <a data-cy="by-number" routerLink="/fai/number" (click)="onTabClick(TYPES.NUMBER)"
                                                        [class.is-active]="currentType === TYPES.NUMBER"
                                                        [attr.aria-selected]="currentType === TYPES.NUMBER" role="tab">Testez avec un numéro de téléphone ou PTO
                                                    </a>
                                                </li>
                                                <li role="presentation">
                                                    <a data-cy="by-imb" routerLink="/fai/imb" (click)="onTabClick(TYPES.IMB)"
                                                        [class.is-active]="currentType === TYPES.IMB"
                                                        [attr.aria-selected]="currentType === TYPES.IMB" role="tab">Testez avec IMB
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="column is-narrow" *ngIf="!orderRecovery">
                                        <tlv-weto-eligibility-map></tlv-weto-eligibility-map>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="isLoading">
                                @switch (currentType) {
                                    @case (TYPES.ADDRESS) {
                                        <tlv-weto-map
                                            (currentStepEvent)="onStepDataUpdate($event)"
                                            (firstStep)="fsAddress=$event"
                                            (end)="ended($event)"
                                        />
                                    }
                                    @case (TYPES.NUMBER) {
                                        <tlv-weto-nd
                                            (currentStepEvent)="onStepDataUpdate($event)"
                                            (firstStep)="fsNd=$event"
                                            (end)="ended($event)"
                                        />
                                    }
                                    @case (TYPES.IMB) {
                                        <tlv-weto-imb
                                            (currentStepEvent)="onStepDataUpdate($event)"
                                            (firstStep)="fsImb=$event"
                                            (end)="ended($event)"
                                        />
                                    }
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
