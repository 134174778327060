import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderModel } from '@models/order/order.model';
import { WetoStorage } from '@repositories/storages/weto.storage';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { FAI_ROUTES, FAI_WIDGET_ROUTES } from 'src/app/constants/fai';

export enum ALLOWED_TYPES {
    NUMBER = 'NUMBER',
    ADDRESS = 'ADDRESS',
    IMB = 'IMB',
}

@Component({
    selector: 'tlv-eligibility',
    templateUrl: './eligibility.component.html',
    styleUrls: ['./eligibility.component.scss']
})
export class EligibilityComponent implements OnInit {

    public static TYPES = ALLOWED_TYPES;
    public readonly ROUTES = FAI_WIDGET_ROUTES;
    public readonly TYPES = EligibilityComponent.TYPES;
    public currentType: ALLOWED_TYPES;

    public fsAddress: boolean = true;
    public fsNd: boolean = true;
    public fsImb: boolean = true;
    public end: boolean = false;
    public currentStepData: any;
    public isCurrentStep: boolean = true;
    public orderRecovery: OrderModel;
    public isLoading: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private salesForceService: SalesForceService,
        private faiEligibilityService: FaiEligibilityService
    ) { }

    public ngOnInit(): void {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.currentType = this.route.snapshot.data.type || ALLOWED_TYPES.ADDRESS;
        this.faiEligibilityService.currentWidget = this.currentType;
        this.isLoading = false;
    }

    public ended(data: Event): void {
        this.router.navigateByUrl(FAI_ROUTES.TECHNO, {state: data});
        this.end = true;
    }

    public onStepDataUpdate(data: Event): void {
        this.isLoading = true;
        this.currentStepData = data;
        this.isCurrentStep = this.currentStepData?.name === this.ROUTES.FUNNEL || this.currentStepData?.name === this.ROUTES.NUMBER
            || this.currentStepData?.name === this.ROUTES.MAP || this.currentStepData?.name === this.ROUTES.NUMBER_PTO;
        this.isLoading = false;
    }

    public onTabClick(widget_type: ALLOWED_TYPES): void {
        this.faiEligibilityService.currentWidget = widget_type;
        WetoStorage.clear();
    }

}
